<template>
  <section>
    <div class="col-md-12" style="padding:0px !important;" v-show="!SignalRConnectionActive">
      <b-alert :show="true" variant="primary" style="margin-bottom: 0px !important">
        <div class="col-md-8">
          <h4 class="alert-text kt-font-bold kt-font-dark">
            {{ $t('OnlineMeetingComponent.InviteToMeeting') }}
          </h4>
        </div>
        <div class="col-md-4" style="text-align-last: end;">
          <b-dropdown variant="none iq-waves-effect remove-toggle">
            <template v-slot:button-content>
              <font-awesome-icon icon="fa-sharp fa-light fa-share-all" size="xl" />
            </template>
            <b-dropdown-item @click="copyClipboard">
              <font-awesome-icon icon="fa-regular fa-copy" size="sm" /> &nbsp; {{$t('CopyLink')}}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-alert>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'MeetingInvitationComponent',
    props: {
      SignalRConnectionActive: Boolean,
      meetingUrl: String
    },
    methods: {
      copyClipboard() {
        const el = document.createElement('textarea');
        el.value = this.meetingUrl;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    }
  }
</script>
